import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getOrderByNumber } from '../selectors';

import { closePopup } from '../actions/popup';

import { getImageSrc } from '../utils';
import { BASE_ZINDEX } from '../popup-factory';

import Img from './Img';
import UpgradeOrderBtn from './order/UpgradeOrderBtn';
import { getNewOrderedItemsForUpgrade } from '../helpers/order';

class UpgradeEstimatePopup extends Component {

  constructor(props) {
    super(props);

    this.state = props.order.items.reduce((o, item) => { item.parent_type == 'PRODUCT' ? o[item.item_id] = 0 : o[item.item_id] = 1; return o; }, {});
    this.state.items = props.order.items.filter(i => i.parent_type === 'PRODUCT');
    this.state.newItems = props.order.items.filter(i => i.parent_type !== 'PRODUCT');

    this.addItem = this.addItem.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.checkAll = this.checkAll.bind(this);
    this.uncheckAll = this.uncheckAll.bind(this);
  }

  addItem(item) {
    this.setState({ [item.item_id]: !this.state[item.item_id] });
    this.setState({ newItems: this.state.newItems.slice().concat([item])});
  }

  removeItem(item) {
    this.setState({ [item.item_id]: !this.state[item.item_id] });
    this.setState({ newItems: this.state.newItems.filter(i => i.item_id !== item.item_id)});
  }

  checkAll(items) {
    for(var i = 0; i < items.length; i++) {
      let item_id = items[i].item_id;
      this.setState({ [item_id]: 1});
    }
    this.setState({ newItems: this.props.order.items.filter(i => i.hidden != 1) });
  }

  uncheckAll(items) {
    for(var i = 0; i < items.length; i++) {
      let item_id = items[i].item_id;
      this.setState({ [item_id]: 0});
    }
    this.setState({ newItems: this.props.order.items.filter(i => i.parent_type !== 'PRODUCT') });
  }

  render() {
    const { order, onClosePopup, capabilities, index } = this.props;
    const newItems = getNewOrderedItemsForUpgrade(this.state.newItems, order.items);
    const items = this.state.items;
    const hidden = {
      display: 'none'
    };
    const hasCapability = capability => capabilities.includes(capability);

    return (
      <div id="convert-modal" className ="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index}} data-reveal aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className ="row">
          <div className ="small-12 columns">
            <h3 id="modalTitle">Convert Estimate To Sales Order</h3>
            <a className="alert button" style={{ position: 'fixed', right: '6.5rem', top: '1rem' }} onClick={e => onClosePopup()}>Cancel</a>
            {hasCapability('CREATE-SALES-ORDER')
              ? <UpgradeOrderBtn
                  items={newItems}
                  parent_id={order.order_id}
                  target_type='UPGRADE'
                  job_id={this.props.job_id}
                  job_number={this.props.job_number}
                  fromOrderType='ESTIMATE'
                  toOrderType='SALES ORDER'
                />
              : null}
          </div>
        </div>
        <div className ="row popup-content">
          <div className ="small-12 columns popup-size-limit">
            <table className ="convert-products-table">
              <thead>
                <tr>
                  <th><input type="checkbox" name="check-all" onChange={e => e.target.checked ? this.checkAll(items) : this.uncheckAll(items)} /></th>
                  <th>Products</th>
                  <th>Units</th>
                  <th>Amount</th>
                  <th>Margin</th>
                  <th>Tax</th>
                </tr>
              </thead>
              <tbody>
              {items.map(i =>
                <tr key={i.item_id} style={i.hidden == 1 ? hidden : null}>
                  <td><input type="checkbox" checked={this.state[i.item_id]} onClick={e => this.state[i.item_id] == 1 ? this.removeItem(i) : this.addItem(i)} /></td>
                  <td><Img src={getImageSrc(i.item_images.map(ii => ii.image)[0])} /><a href="#">{i.item_name}</a></td>
                  <td>{i.total_units}</td>
                  <td>{i.total_subtotal}</td>
                  <td>{i.total_margin}</td>
                  <td>{i.tax_amounts.map(t => t.label)}</td>
                </tr>
              )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const order = getOrderByNumber(state, { form_number: ownProps.form_number, order_type: 'ESTIMATE' });
  return {
    order,
    capabilities: state.identity.capabilities
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => {
      dispatch(closePopup());
    },
  };
};

const ConnectedUpgradeEstimatePopup = connect(mapStateToProps, mapDispatchToProps)(UpgradeEstimatePopup);
export default ConnectedUpgradeEstimatePopup;
